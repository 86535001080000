import facebookLogo from "../assets/facebook-logo.png";
import instagramLogo from "../assets/instagram-logo.png";
import spotifyLogo from "../assets/spotify-logo.png";
import youtubeLogo from "../assets/youtube-logo.png";

function Social() {
  const social = [
    {
      link: "https://facebook.com/kidsaoficial",
      icon: facebookLogo,
      alt: "Facebook",
    },
    {
      link: "https://instagram.com/kidsaoficial",
      icon: instagramLogo,
      alt: "Instagram",
    },
    {
      link: "https://open.spotify.com/artist/4uxWlzCOE5Qlywc0C34t1D?si=ts1fQJwRTZmYcVg_50MbAg",
      icon: spotifyLogo,
      alt: "Spotify",
    },
    {
      link: "https://www.youtube.com/c/KidsaEnglish",
      icon: youtubeLogo,
      alt: "YouTube",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <span className="font-bold">FOLLOW US</span>
      <ul className="inline-flex items-center justify-center gap-1">
        {social.map((item, index) => (
          <li key={index}>
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block p-1"
            >
              <img src={item.icon} alt={item.alt} width={30} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Social;
