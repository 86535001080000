import Header from "./components/Header";
import Info from "./components/Info";
import kidsaLogoWhite from "./assets/kidsa-logo-white.png";

function Congratulations() {
  return (
    <>
      <Header
        title="WELCOME ABOARD"
        description="Que bom ter você aqui, esperamos uma divertida e enriquecedora relação de aprendizagem e parceria, tudo para sua criança aproveitar ao máximo a Experiência Kidsa."
        image={kidsaLogoWhite}
        bgChild="bg-[url(./assets/child.png)]"
      />
      <Info />
    </>
  );
}

export default Congratulations;
