import Button from "./Button";
import FacebookPixel from "./FacebookPixel";

function Header({ title, description, image, bgChild }) {
  return (
    <>
      <FacebookPixel pixelId="2500379879980686" />
      <header className="bg-blue bg-[url('./assets/background-header.png')]">
        <div
          className={`main h-full min-h-[390px] flex flex-col gap-8 ${bgChild} bg-no-repeat bg-bottom md:bg-right-bottom`}
        >
          <img
            src={image}
            alt="Kidsa English"
            className="mx-auto mt-4"
            width={142}
          />
          <div className="flex flex-col w-full md:w-1/2 mb-96 md:mb-4">
            <h1 className="H1">{title.toUpperCase()}</h1>
            <p className="paragraph text-white text-center md:text-left my-2 md:my-7">
              {description}
            </p>
            <Button
              text="Começar"
              className="mx-auto md:mx-0"
              onClick={() => {
                document.querySelector("#middle").scrollIntoView({
                  behavior: "smooth",
                });
              }}
            />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
