import Header from "./components/Header";
import Plan from "./components/Plan";
import kidsaLogoWhite from "./assets/kidsa-logo-white.png";

function LinkToBuy() {
  return (
    <>
      <Header
        title="BEM-VINDO AO KIDSA!"
        description="Sua criança irá se juntar aos mais de 10 mil alunos de todo o
            Brasil. Vamos nos divertir e aprender juntos!"
        image={kidsaLogoWhite}
        bgChild="bg-[url(./assets/children.png)]"
      />
      <Plan />
    </>
  );
}

export default LinkToBuy;
