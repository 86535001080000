import Button from "./Button";
import YoutubeEmbed from "./YoutubeEmbed";

function InfoClub() {
  return (
    <>
      <div className="py-8" id="middle">
        <div className="main">
          <h2 className="H2 mb-8">Informações importantes</h2>
          <div className="mb-8 overflow-auto">
            <p className="paragraph">
              Já enviamos para o seu e-mail cadastrado o usuário e a senha para acessar a plataforma do Kidsa, e uma cópia do nosso contrato.
              <br />
              Em breve você receberá nossa ligação de boas vindas para confirmação de seus dados.
            </p>
            <Button
              text="Plataforma Kidsa"
              className="block mx-auto mt-12"
              onClick={() =>
                window.open("https://www.play.kidsa.com", "_blank")
              }
            />
          </div>
        </div>
      </div>
      <div className="py-8 bg-blue-light">
        <div className="main">
          <h2 className="H2 mb-2">E Já vamos começar!</h2>
          <h3 className="H3 mb-4">
             Assista o tutorial e veja como reservar as suas primeiras atividades do Club!
          </h3>
          <p className="paragraph text-center">
            <br></br>
            <div className="paragraph">
              <YoutubeEmbed embedId="_r5GBYf5KgY" />
            </div>
          </p>
        </div>
      </div>
      <div className="py-8">
        <div className="main">
          <h2 className="H2 mb-2">Links importantes</h2>
          <div className="flex flex-col md:flex-row gap-8">
            <div>
              <h3 className="H3 mb-4">Portal do Club!</h3>
              <p className="paragraph">
                No portal do Club!, você conseguirá marcar os aulões divertidos do Kidsa Club!
              </p>
              <br></br>
              <Button
                text="Portal do Club!"
                className="block mx-auto"
                blue
                onClick={() =>
                  window.open("https://www.play.kidsa.com", "_blank")
                }
              />
            </div>
            <div>
              <h3 className="H3 mb-4">Kit de boas-vindas</h3>
              <p className="paragraph">
                No link abaixo, separamos dicas e respondemos dúvidas importantes para que sua criança tenha a melhor experiêcia aqui no Kidsa.
              </p>
              <br></br>
              <Button
                text="Kit boas-vindas"
                className="block mx-auto"
                blue
                onClick={() =>
                  window.open("https://kidsa.com/boas-vindas-kidsa", "_blank")
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InfoClub;
