function InfoApp() {
  return (
    <>
      <div className="py-8" id="middle">
        <div className="main">
          <div className="mb-8 overflow-auto">
            <p className="paragraph">
              Para baixar o aplicativo do Kidsa English, clique no link abaixo, ou busque por Kidsa English na sua loja de aplicativos:
              <br/>
              <br/>
            </p>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3" role="presentation" width="100%">
                <tbody>
                  <tr>
                      <td>
                        <table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" width="600">
                            <tbody>
                              <tr>
                                  <td class="column column-1" width="50%">
                                    <table border="0" cellpadding="0" cellspacing="0" class="image_block" role="presentation" width="100%">
                                        <tr>
                                          <td>
                                              <div align="center"><a href="https://play.google.com/store/apps/details?id=com.kidsa.english" tabindex="-1" target="_blank"><img src="https://api-pag-link.kidsa.com/images/icon_google.png" width="150"/></a></div>
                                          </td>
                                        </tr>
                                    </table>
                                  </td>
                                  <td class="column column-2" width="50%">
                                    <table border="0" cellpadding="0" cellspacing="0" class="image_block" role="presentation" width="100%">
                                        <tr>
                                          <td>
                                              <div align="center"><a href="https://apps.apple.com/br/app/kidsa-english/id1429822492" tabindex="-1" target="_blank"><img src="https://api-pag-link.kidsa.com/images/icon_apple_store.png" width="150"/></a></div>
                                          </td>
                                        </tr>
                                    </table>
                                  </td>
                              </tr>
                            </tbody>
                        </table>
                      </td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default InfoApp;
