import Button from "./Button";
import calendar from "../assets/calendar.png";
import YoutubeEmbed from "./YoutubeEmbed";

function Info() {
  return (
    <>
      <div className="py-8" id="middle">
        <div className="main">
          <h2 className="H2 mb-8">Informações importantes</h2>
          <div className="mb-8 overflow-auto">
            <p className="paragraph">
              Já enviamos para o seu e-mail cadastrado o usuário e a senha para acessar a plataforma do Kidsa, e uma cópia do nosso contrato.
              <br />
              Em breve você receberá nossa ligação de boas vindas para confirmação de seus dados.
            </p>
            <Button
              text="Plataforma Kidsa"
              className="block mx-auto mt-12"
              onClick={() =>
                window.open("https://www.play.kidsa.com", "_blank")
              }
            />
          </div>
        </div>
      </div>
      <div className="py-8 bg-blue-light bg-opacity-50" id="middle">
        <div className="main">
          <h2 className="H2 mb-2">E Já vamos começar!</h2>
          <h3 className="H3 mb-4">
             Assista o tutorial e veja como reservar as suas primeiras aulas:
          </h3>
          <p className="paragraph text-center">
            <br></br>
            <div className="paragraph">
              <YoutubeEmbed embedId="R1krhTSalYg" />
            </div>
          </p>
        </div>
      </div>
      {/* <div className="py-8 bg-blue-light">
        <div className="main">
          <h3 className="H3 mb-4">
            REUNIÃO PARA LIBERAÇÃO DOS CRÉDITOS PARA AS AULAS AO VIVO
          </h3>
          <p className="paragraph">
            Enquanto a turma está se formando, sua criança já pode começar a realizar as aulas ao vivo com nossos professores. Para isso, você deve participar de nossa reunião de pais/responsáveis. Nesse encontro apresentaremos nossa plataforma e tudo o que o Kidsa tem para oferecer para sua família. Vamos também esclarecer todas as suas dúvidas para que tudo dê certo ao longo da nossa caminhada!
            <br />
            As reuniões acontecem diariamente.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-8 my-8">
            <div className="flex flex-col items-center justify-center">
              <div className="bg-blue p-4 rounded-full">
                <img src={calendar} alt="Calendário" width={65} />
              </div>
              <p className="paragraph text-center">
                Segundas, quartas e sextas-feiras:
                <br />
                16 horas
              </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="bg-blue p-4 rounded-full">
                <img src={calendar} alt="Calendário" width={65} />
              </div>
              <p className="paragraph text-center">
                Terças e quintas:
                <br />
                19 horas
              </p>
            </div>
          </div>
          <span className="block mx-auto mt-4 mb-4 w-fit font-bold">
            Link para acesso à reunião pelo ZOOM:
          </span>
          <Button
            text="Reunião pelo Zoom"
            className="block mx-auto"
            onClick={() =>
              window.open("https://us02web.zoom.us/j/83418485706", "_blank")
            }
          />
        </div>
      </div> */}
      <div className="py-8 bg-blue-light">
        <div className="main">
          <h2 className="H2 mb-2">Links importantes</h2>
          <div className="flex flex-col md:flex-row gap-8">
            <div>
              <h3 className="H3 mb-4">Portal do Aluno</h3>
              <p className="paragraph">
                No portal do aluno, você conseguirá acessar a sua turma, marcar as aulas introdutórias, os aulões divertidos e reservar as atividades do Kidsa Dooo!
              </p>
              <Button
                text="Portal do aluno"
                className="block mx-auto"
                blue
                onClick={() =>
                  window.open("https://www.play.kidsa.com", "_blank")
                }
              />
            </div>
            <div>
              <h3 className="H3 mb-4">Kit de boas-vindas</h3>
              <p className="paragraph">
                No link abaixo, separamos dicas e respondemos dúvidas importantes para que sua criança tenha a melhor experiêcia aqui no Kidsa.
              </p>
              <Button
                text="Kit boas-vindas"
                className="block mx-auto"
                blue
                onClick={() =>
                  window.open("https://kidsa.com/boas-vindas-kidsa", "_blank")
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Info;
