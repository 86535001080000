import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useFormik } from "formik";
import * as Yup from "yup";
import Form from "./Form";
import DiscountForm from "./DiscountForm";
import {
  fullname,
  email as emailCheck,
  phone as phoneCheck,
  document as documentCheck,
  minAndMaxAge,
  checked,
} from "../utils/schemas";

const base_url = "https://api-pag-link.kidsa.com";
const base_url_teste = "http://localhost:3000";

function Plan() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const plid = searchParams.get("plid");

  const [data, setData] = useState(null);
  const [expired, setExpired] = useState(false);
  const [code, setCode] = useState("");
  const [discountCode, setDiscountCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      current_id: 0,
      name: "",
      email: "",
      document: "",
      phone: "",
      student_name: "",
      student_birthdate: "",
      contract_url: false,
      termination_term_url: false,
    },
    validationSchema: Yup.object({
      name: fullname(),
      email: emailCheck(),
      phone: phoneCheck(),
      document: documentCheck(),
      student_name: fullname(),
      student_birthdate: minAndMaxAge(),
      contract_url: checked("Leia e aceite o contrato"),
      termination_term_url: checked("Leia e aceite o contrato de cancelamento"),
    }),
    onSubmit: (values) => {
      generateCheckout(values);
    },
  });

  const generateCheckout = async (values) => {
    setLoading(true);

    var params = { ...values };

    if (code && code.length > 0) {
      params.discount_code = code;
    } else {
      params.discount_code = "";
    }

    if (data && data.show_input_fields !== undefined) {
      params.show_input_fields = data.show_input_fields;
    } else {
      params.show_input_fields = true;
    }

    const response = await fetch(
      `${base_url}/payment-link/${values.current_id}/payment-url`,
      {
        headers: {
          "x-api-key": "2f2c83ffe736324c1f0af42fc471cd50",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(params),
      }
    );
    const json = await response.json();

    if (json.meta.code < 400) {
      window.open(json.url, "_self");
    } else {
      alert(json.message);
    }

    setLoading(false);
  };

  const fetchDiscount = async (discount_code) => {
    setLoading(true);

    const response = await fetch(`${base_url}/discount-code/verify`, {
      headers: {
        "x-api-key": "2f2c83ffe736324c1f0af42fc471cd50",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        discount_code: discount_code,
        payment_link_id: data.id,
      }),
    });

    const json = await response.json();

    setLoading(false);

    if (json.meta.code < 400) {
      setDiscountCode(json.result);
      setCode(discount_code);
      alert("Desconto aplicado");
      return;
    }

    setDiscountCode(null);
    setCode("");
    alert(json.result);
  };

  const fetchData = async () => {
    setLoading(true);

    if (token !== null) {
      const data = jwt_decode(token);
      setData(data);
      formik.setFieldValue("current_id", data.id);
      setLoading(false);
      return;
    }

    const response = await fetch(`${base_url}/link/${plid}`, {
      headers: {
        "x-api-key": "2f2c83ffe736324c1f0af42fc471cd50",
        "Content-Type": "application/json",
      },
      method: "GET",
    });

    const json = await response.json();

    if (typeof json.result === "undefined") {
      setExpired(true);
      setData(null);
      return;
    }

    setData(json.result);
    formik.setFieldValue("current_id", json.result.id);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!data && expired) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "150px",
          marginBottom: "150px",
        }}
      >
        <h1>
          Lamentamos informar que a página que você está tentando acessar
          expirou e não está mais disponível.
        </h1>
        <p>Por favor entre em contato conosco para que possamos ajudá-lo.</p>
      </div>
    );
  }

  if (!data && !expired) {
    return <></>;
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Form
        formik={formik}
        data={data}
        discountCode={discountCode}
        loading={loading}
      >
        {data.allow_discount_code && (
          <DiscountForm fetchDiscount={fetchDiscount} />
        )}
      </Form>
    </form>
  );
}

export default Plan;
