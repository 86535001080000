import Social from "./Social";
import kidsaLogoWhite from "../assets/kidsa-logo-color.png";

function Footer() {
  return (
    <footer className="bg-gray h-[290px]">
      <div className="flex flex-col items-center justify-center h-full gap-4">
        <div className="main flex flex-col sm:flex-row items-center justify-between gap-8">
          <img src={kidsaLogoWhite} alt="Kidsa English" width={260} />
          <Social />
        </div>
        <div className="main">
          <hr className="border-gray-dark my-4" />
          <span className="block text-center text-gray-darkest w-full">
            © Copyright - Kidsa English{" "}
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
