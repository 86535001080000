import React from "react";
import InputMask from "react-input-mask";
import Button from "./Button";

const Form = ({ formik, data, discountCode, loading, children }) => {
  const {
    has_discount,
    product_name,
    value_format,
    trial_period,
    show_input_fields,
    next_sunday,
    value_with_discount_format, //
    value_installment, //
    number_max_installments,
    contract_url,
    termination_term_url,
    validity,
    allow_discount_code,
  } = data;

  // Se o plano tiver '7 dias grátis' no nome, é um plano com período de teste
  // const sete_dias_gratis_regex = new RegExp('^.+7 DIAS GRÁTIS.*$');

  return (
    <>
      <input type="hidden" name="current_id" value={formik.values.current_id} />
      <div className="py-8 my-8" id="middle">
        <div className="main">
        {show_input_fields && (
          <>
            <div className="mb-8">
              <h2 className="H2 py-4">Preencha seus dados para o contrato</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Nome do responsável"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    className="border border-black rounded-md w-full p-2"
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <span className="error">{formik.errors.name}</span>
                  ) : null}
                </div>

                <div>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    name="document"
                    placeholder="CPF do responsável"
                    onChange={formik.handleChange}
                    value={formik.values.document}
                    className="border border-black rounded-md w-full p-2"
                  />
                  {formik.touched.document && formik.errors.document ? (
                    <span className="error">{formik.errors.document}</span>
                  ) : null}
                </div>

                <div>
                  <input
                    type="text"
                    name="email"
                    placeholder="E-mail do responsável"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className="border border-black rounded-md w-full p-2"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span className="error">{formik.errors.email}</span>
                  ) : null}
                </div>

                <div>
                  <InputMask
                    mask="(99) 99999-9999"
                    type="text"
                    name="phone"
                    placeholder="Whatsapp do responsável"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    className="border border-black rounded-md w-full p-2"
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <span className="error">{formik.errors.phone}</span>
                  ) : null}
                </div>

                <div>
                  <input
                    type="text"
                    name="student_name"
                    placeholder="Nome completo do aluno"
                    value={formik.values.student_name}
                    onChange={formik.handleChange}
                    className="border border-black rounded-md w-full p-2"
                  />
                  {formik.touched.student_name && formik.errors.student_name ? (
                    <span className="error">{formik.errors.student_name}</span>
                  ) : null}
                </div>

                <div>
                  <InputMask
                    mask="99/99/9999"
                    type="text"
                    name="student_birthdate"
                    placeholder="Data de nascimento do aluno"
                    onChange={formik.handleChange}
                    value={formik.values.student_birthdate}
                    className="border border-black rounded-md w-full p-2"
                  />
                  {formik.touched.student_birthdate &&
                  formik.errors.student_birthdate ? (
                    <span className="error">
                      {formik.errors.student_birthdate}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}

          <div className="rounded-xl border-2 border-black overflow-auto">
            <h2 className="H2 bg-blue-light py-4">Meu plano escolhido</h2>
            <div className="py-6">
              <h3 className="H3 text-center">{product_name}</h3>
              <p className="paragraph text-center">
                {has_discount === true ||
                has_discount === "true" ||
                discountCode ? (
                  <>
                    De {value_format},{" "}
                    <strong>
                      por{" "}
                      {discountCode?.value_with_discount_format ??
                        value_with_discount_format}
                    </strong>
                  </>
                ) : (
                  `Valor ${value_format}`
                )}
                <br />
                {number_max_installments > 0 && (
                  <>
                    em até {number_max_installments} parcelas de{" "}
                    {discountCode
                      ? discountCode.value_installment
                      : value_installment}
                  </>
                )}
              </p>
              {(has_discount === true ||
                has_discount === "true" ||
                discountCode) && (
                <p className="text-sm text-center mt-4">
                  Desconto válido até: {discountCode?.validity ?? validity}
                </p>
              )}

              {/* <p className="text-sm text-center mt-4">*Valores válidos até {next_sunday}</p> */}
              <p className="text-sm text-center mt-4">*Valores válidos até 05/02/2024</p>

              {allow_discount_code && children}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-light py-8 my-8">
        <div className="main">
          <h2 className="H2">Termo de consentimento</h2>
          <h3 className="H3 mb-4">
            Leia com atenção e cuidado os termos do seu contrato.{" "}
          </h3>

          <fieldset>
            <input
              type="checkbox"
              name="contract_url"
              checked={formik.values.contract_url}
              onChange={formik.handleChange}
            />{" "}
            <label htmlFor="contract_url" className="label">
              Li e concordo com o{" "}
              <a
                href={contract_url}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                contrato
              </a>
              .
            </label>
            {formik.touched.contract_url && formik.errors.contract_url ? (
              <span className="error">{formik.errors.contract_url}</span>
            ) : null}
          </fieldset>

          <fieldset>
            <input
              type="checkbox"
              name="termination_term_url"
              checked={formik.values.termination_term_url}
              onChange={formik.handleChange}
            />{" "}

            {/* Verificação de que o plano é um plano com período de teste ou não */}
            {/* sete_dias_gratis_regex.test( product_name.toUpperCase()) */
               trial_period > 0 ? (
                    <>
                    <label htmlFor="termination_term_url" className="label">
                      Sei que posso cancelar o plano antes da primeira cobrança dentro do período de gratuidade ou a qualquer momento durante a sua vigência, entrando em contato com o suporte.
                    </label>
                    {formik.touched.termination_term_url &&
                    formik.errors.termination_term_url ? (
                      <span className="error">
                        {formik.errors.termination_term_url}
                      </span>
                    ) : null}

                    </>
                ) : ( 
                    <> 

                    <label htmlFor="termination_term_url" className="label">
                      Li e concordo com a cláusula de cancelamento presente no{" "}
                      <a
                        href={termination_term_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        contrato
                      </a>
                      .
                    </label>
                    {formik.touched.termination_term_url &&
                    formik.errors.termination_term_url ? (
                      <span className="error">
                        {formik.errors.termination_term_url}
                      </span>
                    ) : null}

                    </> 
                ) 
            }

          </fieldset>

          {loading && (
            <span className="block mt-8 text-center text-white font-bold">
              Aguarde um momento...
            </span>
          )}

          <Button
            type="submit"
            disabled={loading}
            text="Continuar"
            className="block mx-auto mt-4 disabled:bg-opacity-10"
          />
        </div>
      </div>
    </>
  );
};

export default Form;
