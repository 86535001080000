import * as Yup from "yup";
import moment from "moment";
import valid from "card-validator"; //import statement

const zipcode = (
  message = "É necessário informar o cep",
  required = "Obrigatório"
) => {
  return Yup.string()
    .matches(/^([0-9]{5}-[0-9]{3}$)/, message)
    .required(required);
};

const fullname = (
  message = "É necessário informar nome e sobrenome",
  required = "Obrigatório"
) => {
  return Yup.string()
    .matches(
      /^([a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{3,}\s[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2,}?)/,
      message
    )
    .required(required);
};

const name = (message = "Nome inválido", required = "Obrigatório") => {
  return Yup.string().min(3, message).required(required);
};

const url = (message = "URL inválida", required = "Obrigatório") => {
  return Yup.string()
    .matches(
      /^(http:\/\/www.|https:\/\/www.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      message
    )
    .required(required);
};

const facebook = (message = "URL inválida", required = "Obrigatório") => {
  return Yup.string()
    .matches(
      /^(https:)\/\/(?:www\.)?(?:facebook|fb)\.com\/((?![A-z]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)[A-z0-9_\-\.]+)\/?$/,
      message
    )
    .required(required);
};

const instagram = (message = "URL inválida", required = "Obrigatório") => {
  return Yup.string()
    .matches(
      /^(https:)\/\/(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/,
      message
    )
    .required(required);
};

const email = (message = "E-mail inválido", required = "Obrigatório") => {
  return Yup.string().email(message).required(required);
};

const phone = (message = "Celular inválido", required = "Obrigatório") => {
  return Yup.string().min(14, message).required(required);
};

const password = (message = "Senha inválida", required = "Obrigatória") => {
  return Yup.string().min(6, message).required(required);
};

const passport = (
  message = "Passaporte inválido",
  required = "Obrigatória"
) => {
  return Yup.string().min(5, message).required(required);
};

const document = (message = "CPF inválido", required = "Obrigatório") => {
  return Yup.string()
    .required(required)
    .test("valid-cpf", message, (val) => {
      if (!val) return false;

      const cpf = val.replace(/[^\w\s]/gi, "");

      var sum = 0;
      var rest;

      if (
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999" ||
        cpf === "12345678909"
      )
        return false;

      for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
      }

      rest = (sum * 10) % 11;

      if (rest === 10 || rest === 11) {
        rest = 0;
      }

      if (rest !== parseInt(cpf.substring(9, 10))) {
        return false;
      }

      sum = 0;

      for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
      }

      rest = (sum * 10) % 11;

      if (rest === 10 || rest === 11) {
        rest = 0;
      }

      if (rest !== parseInt(cpf.substring(10, 11))) {
        return false;
      }

      return true;
    });
};

const cnpj = (message = "CNPJ inválido", required = "Obrigatório") => {
  return Yup.string()
    .required(required)
    .test("valid-cnpj", message, (val) => {
      // https://www.geradorcnpj.com/javascript-validar-cnpj.htm
      if (!val) return false;

      const cnpj = val.replace(/[^\d]+/g, "");

      if (cnpj === "") return false;

      if (cnpj.length !== 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999"
      )
        return false;

      // Valida DVs
      var tamanho = cnpj.length - 2;
      var numeros = cnpj.substring(0, tamanho);
      const digitos = cnpj.substring(tamanho);

      var soma = 0;
      var pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }

      var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== digitos.charAt(1)) return false;

      return true;
    });
};

const subject = (message = "Assunto inválido", required = "Obrigatório") => {
  return Yup.string().min(3, message).required(required);
};

const message = (message = "Mensagem inválida", required = "Obrigatório") => {
  return Yup.string().min(10, message).required(required);
};

const minLength = (
  length = 1,
  message = "Valor inválido",
  required = "Obrigatório"
) => {
  return Yup.string().min(length, message).required(required);
};

const maxLength = (
  length = 1,
  message = "Valor inválido",
  required = "Obrigatório"
) => {
  return Yup.string().max(length, message).required(required);
};

const optionNumber = (message = "Opção inválida", required = "Obrigatório") => {
  return Yup.number().moreThan(-1, message).required(required);
};

const minAndMaxAge = (message = "Idade inválida", required = "Obrigatório") => {
  function getYears(value) {
    return moment().diff(moment(value, "DD/MM/YYYY", true), "years");
  }

  return Yup.mixed()
    .required(required)
    .test("test-age", message, (val) => {
      let age = getYears(val);
      return age >= 0 && age <= 120;
    });
};

const nowOrFutureDate = (
  message = "Data inválida",
  required = "Obrigatório"
) => {
  function getDate(value) {
    return moment(value, "MM/YYYY", true).diff(moment(new Date()), "months");
  }

  return Yup.mixed()
    .required(required)
    .test("test-date", message, (value) => {
      const date = getDate(value);
      return date >= 0;
    });
};

const creditCard = (
  message = "Cartão de crédito inválido",
  required = "Obrigatório"
) => {
  return Yup.string()
    .required(required)
    .test("test-credit-card", message, (value) => {
      return valid.number(value).isValid;
    });
};

const file = (
  sizeMessage = "O arquivo é muito grande",
  formatMessage = "Arquivo é inválido",
  required = "Obrigatória"
) => {
  return Yup.mixed()
    .nullable()
    .required(required)
    .test("file-size", sizeMessage, (value) => {
      const valid = !value || (value && value.size <= 1024 * 1024);
      return valid;
    })
    .test("file-format", formatMessage, (value) => {
      const valid =
        !value ||
        (value &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value.type));
      return valid;
    });
};

const checked = (message = "Deve ser marcado", required = "Obrigatório") => {
  return Yup.bool().oneOf([true], message).required(required);
};

export {
  zipcode,
  fullname,
  name,
  url,
  facebook,
  instagram,
  email,
  phone,
  password,
  passport,
  document,
  cnpj,
  subject,
  message,
  minLength,
  maxLength,
  optionNumber,
  minAndMaxAge,
  nowOrFutureDate,
  creditCard,
  file,
  checked,
};
