import { BrowserRouter, Routes, Route } from "react-router-dom";
import LinkToBuy from "./LinkToBuy";
import Congratulations from "./Congratulations";
import Footer from "./components/Footer";
import CongratulationsClub from "./CongratulationsClub";
import CongratulationsApp from "./CongratulationsApp";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/congratulations" element={<Congratulations />} />
        <Route path="/congratulations_club" element={<CongratulationsClub />} />
        <Route path="/congratulations_app" element={<CongratulationsApp />} />
        <Route index element={<LinkToBuy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
