function Button({ text, className, blue, ...props }) {
  return (
    <button
      className={`${
        blue ? "bg-blue" : "bg-red"
      } text-white font-bold rounded-full px-4 py-1 text-base md:text-lg max-w-fit ${className}`}
      {...props}
    >
      {text.toUpperCase()}
    </button>
  );
}

export default Button;
