import { useState } from "react";
import Button from "./Button";

function DiscountForm({ fetchDiscount }) {
  const [code, setCode] = useState("");

  return (
    <>
      <div className="mt-8 ">
        <hr />
        <div className="px-2 pt-8 max-w-[235px] mx-auto">
          <div className="flex flex-col gap-1 max-w-lg mx-auto">
            <h5 className="font-bold text-center text-black text-xs">
              CUPOM DE DESCONTO
            </h5>
            <div className="flex flex-row gap-1 items-center justify-center">
              <input
                type="text"
                name="code"
                placeholder="Cupom"
                value={code}
                className="border border-black rounded-md w-full p-2 text-xs"
                onChange={(e) => setCode(e.target.value.toUpperCase())}
              />
              <Button
                type="button"
                text="APLICAR"
                className="text-xs md:text-xs font-bold"
                onClick={() => fetchDiscount(code)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DiscountForm;
